
// import { update, hasUse, detail } from "../../api/model.js";
import { client } from '@/client';
import { defineComponent } from 'vue';
import { ElMessage } from "element-plus";
import { AdminStatus } from '@/shared/types/AdminData';
export default defineComponent({
  name: "model-edit",
  data: () => {
    return {
      params: {
        //接口入参
        id: 0,
        old_table_name: "", //未修改之前的表名称
        model_name: "",
        table_name: "",
        status:"1",
      },
      disable: false,
      paramsRules: {
        //校验规则
        model_name: [
          { required: true, message: "模型名称", trigger: "blur" },
          {
            min: 2,
            max: 80,
            message: "名称长度在 2 到 80 个字符之间",
            trigger: "blur",
          },
        ],
        table_name: [
          { required: true, message: "新增表名", trigger: "blur" },
          {
            min: 2,
            max: 80,
            message: "名称长度在 2 到 80 个字符之间",
            trigger: "blur",
          },
        ],
      },
    };
  },
  async created() {
    let id = this.$route.params.id as string;
    this.params.id = parseInt(id);
    await this.detail(); // 文章详情
    await this.hasUse(this.params.id);
  },
  methods: {
    // 文章详情
    async detail() {
      try {
        let res = await client.callApi("model/Detail",{id:this.params.id});
        if (res.isSucc) {
          let data = res.res;
          //记老的表名，改新表名称
          this.params = { ...data, old_table_name: data.table_name,status:data.status.toString() };
        }
      } catch (error) {
        console.error(error);
      }
    },

    handleAttr(e: any) {
      console.log("e-->", e);
    },

    handleSubCid(e: any) {
      console.log("e-->", e);
    },

    handletag(e: any) {
      console.log("e-->", e);
    },
    handleBox(e: any) {
      console.log("e-->", e);
    },

    async hasUse(id: number) {
      let ret = await client.callApi("model/HasUse",{id:id});
      if (ret.isSucc) {
        if (ret.res.count > 0) {
          this.disable = true;
          return;
        }
      }
      this.disable = false;
    },

    //新增
    async update() {
      let ret = await client.callApi("model/Edit",{
        model_name:this.params.model_name,
        table_name:this.params.table_name,
        status:parseInt(this.params.status) as AdminStatus,
        id:this.params.id
      });
      if (ret.isSucc) {
          ElMessage({
            message: "更新成功^_^",
            type: "success",
          });
        }
        this.$router.go(-1);
    },
    submit(formName: string) {
      let ref:any = this.$refs[formName];
      ref.validate((valid: any) => {
        if (valid) {
          this.update();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
});
