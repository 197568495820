import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mr-10 ml-10 mb-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      ref: "params",
      model: _ctx.params,
      rules: _ctx.paramsRules,
      "label-width": "100px",
      class: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: "模型名称",
          prop: "model_name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.params.model_name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.params.model_name) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "新增表名",
          prop: "table_name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.params.table_name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.params.table_name) = $event)),
              disabled: _ctx.disable
            }, null, 8, ["modelValue", "disabled"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "是否启用" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_radio, {
              modelValue: _ctx.params.status,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.params.status) = $event)),
              label: "1"
            }, {
              default: _withCtx(() => [
                _createTextVNode("开启")
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_el_radio, {
              modelValue: _ctx.params.status,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.params.status) = $event)),
              disabled: _ctx.disable,
              label: "2"
            }, {
              default: _withCtx(() => [
                _createTextVNode("禁用")
              ]),
              _: 1
            }, 8, ["modelValue", "disabled"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.submit('params')))
            }, {
              default: _withCtx(() => [
                _createTextVNode("保存")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ]))
}